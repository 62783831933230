import * as React from "react"
import Seo from "../components/seo"
import Hero from "../components/sections/marketing/Hero";
import MarketingServices from "../components/sections/marketing/MarketingServices";
import { Helmet } from "react-helmet"

const MarketingServicesPage = () => (
  <>
    <Seo title="Marketing Services" />
    <Helmet bodyAttributes={{ class: "header-class" }} />
    <Hero />
    <MarketingServices />
  </>
)

export default MarketingServicesPage

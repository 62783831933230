import styled from "styled-components"
import { Link } from "gatsby"

const white = props => props.theme.colors.light3
const accentSkyBlue = props => props.theme.colors.accentSkyBlue
const accent8 = props => props.theme.colors.accent8

export const subcolor = "#F26A2E"
export const gradient =
  "linear-gradient(to right, rgb(26, 42, 108), rgb(178, 31, 31), rgb(253, 187, 45))"

export const Button = styled(Link)`
  background: ${({ primary, dark, disabled }) =>
    primary ? "#75B3EF" : dark ? "#2B2B2B" : disabled ? 'gray' : accentSkyBlue};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 40px" : "13px 32px")};
  color: ${white};
  font-size: ${({ big }) => (big ? "1rem" : ".8rem")};
  outline: none;
  border: none;
  min-width: ${({ small }) => (small ? "140px" : "250px")};
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  border-radius: ${({ round }) => (round ? "8px" : "none")};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: translateY(-3px);
  }
`